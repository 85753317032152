import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo.png';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

//<div class="devs"><a href="https://www.fiverr.com/appslkofficial" target="_blank">Meet the Devs Here</a> <img src={dev}/></div>

class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">
                  <div class="footer">
                    <div class="logoF"><img src={logo}/></div>
                    <div class="footer2">
                     <div class="footerImg">
                    <img src={os}/>
                    <img src={twitter}/>
                    <img src={discord}/>
                    </div>   
                    <div class="copyright">Copyright © 2023 TT. All Rights Reserved</div>   
                    </div>    
             
                </div>

            </div>
        )
    }
}

export default Footer;

